import { Sheet, SheetHeader, SheetTitle, SheetDescription, SheetContent } from '@/components/ui/sheet';
import CalendarBookingForm from '@/components/forms/calendarBookingForm';
import { DateSelectArg } from '@fullcalendar/core/index.js';
import { UserContext } from '@/contexts/UserContext';
import { useContext } from 'react';
interface AddCalendarBookingProps {
  dateSelected?: DateSelectArg;
  show: boolean;
  setShow: (open: boolean) => void;
}
export default function AddCalendarBooking({
  ...props
}: AddCalendarBookingProps) {
  const userContext = useContext(UserContext);
  return <Sheet open={props.show} onOpenChange={props.setShow} data-sentry-element="Sheet" data-sentry-component="AddCalendarBooking" data-sentry-source-file="addCalendarBooking.tsx">
      <SheetContent data-sentry-element="SheetContent" data-sentry-source-file="addCalendarBooking.tsx">
        <SheetHeader data-sentry-element="SheetHeader" data-sentry-source-file="addCalendarBooking.tsx">
          <SheetTitle data-sentry-element="SheetTitle" data-sentry-source-file="addCalendarBooking.tsx">Add Booking</SheetTitle>
          <SheetDescription data-sentry-element="SheetDescription" data-sentry-source-file="addCalendarBooking.tsx">Provide the booking details below</SheetDescription>
        </SheetHeader>
        <CalendarBookingForm parentShow={props.setShow} date={props.dateSelected?.start} bookingStart={props.dateSelected?.start} bookingEnd={props.dateSelected?.end} userId={userContext.claims?.sub} data-sentry-element="CalendarBookingForm" data-sentry-source-file="addCalendarBooking.tsx" />
      </SheetContent>
    </Sheet>;
}