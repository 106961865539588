import { Table, TableBody, TableCell, TableHead, TableHeader, TableRow } from '@/components/ui/table';
import { Skeleton } from '@/components/ui/skeleton';
import { trpc } from '@/lib/trpc/trpc';
import { IconPlaneDeparture } from '@tabler/icons-react';
export default function Card_AircraftUpcomingBookings() {
  const getAllBookings = trpc.Booking.GetAllBookings.useQuery();
  return <div className='flex flex-grow flex-col rounded-lg border p-3 text-card-foreground shadow' data-sentry-component="Card_AircraftUpcomingBookings" data-sentry-source-file="card_aircraftbookings.tsx">
      <div className='flex flex-row items-center gap-2 p-2'>
        <IconPlaneDeparture data-sentry-element="IconPlaneDeparture" data-sentry-source-file="card_aircraftbookings.tsx" />
        Aircraft Future Bookings
      </div>
      {getAllBookings.data ? getAllBookings.data.length === 0 ? <>
            <div>
              <p>There are no future bookings</p>
            </div>
            <div>
              <p>Time to go flying?</p>
            </div>
          </> : <div>
            <Table>
              <TableHeader>
                <TableRow>
                  <TableHead>Date</TableHead>
                  <TableHead>User</TableHead>
                  <TableHead>Start Time</TableHead>
                  <TableHead>End Time</TableHead>
                </TableRow>
              </TableHeader>
              <TableBody>
                {getAllBookings.data.map((booking, index) => {
            if (index > 3) {
              return;
            }
            return <TableRow key={index}>
                      <TableCell>{`${booking.bookingStart.getDate()}/${booking.bookingStart.getMonth() + 1}/${booking.bookingStart.getFullYear()}`}</TableCell>
                      <TableCell>{booking.bookingUserName}</TableCell>
                      <TableCell>
                        {booking.bookingStart.toLocaleTimeString()}
                      </TableCell>
                      <TableCell>
                        {booking.bookingEnd.toLocaleTimeString()}
                      </TableCell>
                    </TableRow>;
          })}
              </TableBody>
            </Table>
          </div> : <div className='flex flex-col space-y-3'>
          <Skeleton className='h-[125px] w-[250px] rounded-xl' />
          <div className='space-y-2'>
            <Skeleton className='h-4 w-[250px]' />
            <Skeleton className='h-4 w-[200px]' />
          </div>
        </div>}
    </div>;
}