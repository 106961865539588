import { type LogtoContext } from '@logto/next';
import useSWR from 'swr';
import { UserContext } from '@/contexts/UserContext';
import Login from '../auth/login';
export default function AuthWrapper({
  children
}: {
  children: React.ReactNode;
}) {
  //const { data } = useSWR<LogtoContext>(`api/logto/user`);
  const {
    data
  } = useSWR<LogtoContext>('/api/auth/getAccessToken');
  if (data?.isAuthenticated) {
    return <div>
        <UserContext.Provider value={data}>{children}</UserContext.Provider>
      </div>;
  } else {
    return <Login />;
  }
}