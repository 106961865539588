import { DateSelectArg, EventClickArg } from '@fullcalendar/core/index.js';
import { useState } from 'react';
import { trpc } from '@/lib/trpc/trpc';
import DayView from './components/DayView';
import LargeLoadingArea from '../loadings/largeLoading';
import AddCalendarBooking from './components/components/addCalendarBooking';
import EditCalendarBooking from './components/components/editCalendarBooking';
export default function BookingCalendar() {
  const [showAdd, setShowAdd] = useState<boolean>(false);
  const [showEdit, setShowEdit] = useState<boolean>(false);
  const [dateSelected, setDateSelected] = useState<DateSelectArg>();
  const [eventSelected, setEventSelected] = useState<EventClickArg>();
  const getCalendarBookings = trpc.Booking.GetCalendarEvents.useQuery();
  const calendarOnSelect = (selected: DateSelectArg) => {
    setDateSelected(selected);
    setShowAdd(true);
  };
  const calendarOnClick = (event: EventClickArg) => {
    setEventSelected(event);
    setShowEdit(true);
  };
  return <div data-sentry-component="BookingCalendar" data-sentry-source-file="bookingCalendar.tsx">
      {getCalendarBookings.data ? <div>
          <DayView events={getCalendarBookings.data} calendarOnSelect={calendarOnSelect} onClick={calendarOnClick} />
          <AddCalendarBooking setShow={setShowAdd} show={showAdd} dateSelected={dateSelected} />
          <EditCalendarBooking setShow={setShowEdit} show={showEdit} eventSelected={eventSelected} />
        </div> : getCalendarBookings.isLoading ? <LargeLoadingArea /> : <p>Error Loading Data...</p>}
    </div>;
}