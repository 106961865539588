import { Sheet, SheetHeader, SheetTitle, SheetDescription, SheetContent } from '@/components/ui/sheet';
import CalendarBookingForm from '@/components/forms/calendarBookingForm';
import { EventClickArg } from '@fullcalendar/core/index.js';
import { UserContext } from '@/contexts/UserContext';
import { useContext, useState } from 'react';
import DeleteCalendarBookingAlert from './deleteCalendarBooking';
import { Button } from '@/components/ui/button';
interface EditCalendarBookingProps {
  eventSelected?: EventClickArg;
  show: boolean;
  setShow: (open: boolean) => void;
}
export default function EditCalendarBooking({
  ...props
}: EditCalendarBookingProps) {
  const userContext = useContext(UserContext);
  const [showDelete, setShowDelete] = useState<boolean>(false);
  return <Sheet open={props.show} onOpenChange={props.setShow} data-sentry-element="Sheet" data-sentry-component="EditCalendarBooking" data-sentry-source-file="editCalendarBooking.tsx">
      <SheetContent data-sentry-element="SheetContent" data-sentry-source-file="editCalendarBooking.tsx">
        <SheetHeader data-sentry-element="SheetHeader" data-sentry-source-file="editCalendarBooking.tsx">
          <SheetTitle data-sentry-element="SheetTitle" data-sentry-source-file="editCalendarBooking.tsx">Edit Booking</SheetTitle>
          <SheetDescription data-sentry-element="SheetDescription" data-sentry-source-file="editCalendarBooking.tsx">Edit the booking details below</SheetDescription>
        </SheetHeader>
        <CalendarBookingForm parentShow={props.setShow} bookingId={props.eventSelected?.event.id} date={props.eventSelected?.event.start} bookingStart={props.eventSelected?.event.start} bookingEnd={props.eventSelected?.event?.end} comment={props.eventSelected?.event.extendedProps.comment} userId={userContext.claims?.sub} data-sentry-element="CalendarBookingForm" data-sentry-source-file="editCalendarBooking.tsx" />
        {props.eventSelected?.event.id ? <div>
            <br />
            <div className='flex gap-3 py-3'>
              <Button variant='destructive' onClick={() => setShowDelete(true)}>
                Delete Booking
              </Button>
              <DeleteCalendarBookingAlert id={props.eventSelected?.event.id} show={showDelete} setShow={setShowDelete} parentSetShow={props.setShow} />
            </div>
          </div> : <></>}
      </SheetContent>
    </Sheet>;
}