import * as React from 'react';
import { DayPicker, DropdownProps } from 'react-day-picker';
import { cn } from '@/lib/utils';
import { buttonVariants } from '@/components/ui/button';
import { ChevronLeftIcon, ChevronRightIcon } from '@radix-ui/react-icons';
import { Select, SelectValue, SelectContent, SelectItem, SelectTrigger } from './select';
import { ScrollArea } from './scroll-area';
export type CalendarProps = React.ComponentProps<typeof DayPicker>;
function CalendarPicker({
  className,
  classNames,
  showOutsideDays = true,
  ...props
}: CalendarProps) {
  return <DayPicker showOutsideDays={showOutsideDays} defaultMonth={new Date()} toMonth={new Date()} fromDate={new Date(2023, 0)} className={cn('p-3', className)} classNames={{
    months: 'flex flex-col sm:flex-row space-y-4 sm:space-x-4 sm:space-y-0',
    month: 'space-y-4',
    caption: 'flex justify-center pt-1 relative items-center',
    caption_label: cn(props.captionLayout === 'dropdown' ? 'hidden' : 'text-sm font-medium'),
    //caption_label: 'hidden text-sm font-medium',
    caption_dropdowns: 'flex justify-center gap-1',
    nav: 'space-x-1 flex items-center',
    nav_button: cn(buttonVariants({
      variant: 'outline'
    }), 'h-7 w-7 bg-transparent p-0 opacity-50 hover:opacity-100'),
    nav_button_previous: 'absolute left-1',
    nav_button_next: 'absolute right-1',
    table: 'w-full border-collapse space-y-1',
    head_row: 'flex',
    head_cell: 'text-muted-foreground rounded-md w-8 font-normal text-[0.8rem]',
    row: 'flex w-full mt-2',
    cell: cn('relative p-0 text-center text-sm focus-within:relative focus-within:z-20 [&:has([aria-selected])]:bg-accent [&:has([aria-selected].day-outside)]:bg-accent/50 [&:has([aria-selected].day-range-end)]:rounded-r-md', props.mode === 'range' ? '[&:has(>.day-range-end)]:rounded-r-md [&:has(>.day-range-start)]:rounded-l-md first:[&:has([aria-selected])]:rounded-l-md last:[&:has([aria-selected])]:rounded-r-md' : '[&:has([aria-selected])]:rounded-md'),
    day: cn(buttonVariants({
      variant: 'ghost'
    }), 'h-8 w-8 p-0 font-normal aria-selected:opacity-100'),
    day_range_start: 'day-range-start',
    day_range_end: 'day-range-end',
    day_selected: 'bg-primary text-primary-foreground hover:bg-primary hover:text-primary-foreground focus:bg-primary focus:text-primary-foreground',
    day_today: 'bg-accent text-accent-foreground',
    day_outside: 'day-outside text-muted-foreground aria-selected:bg-accent/50 aria-selected:text-muted-foreground',
    day_disabled: 'text-muted-foreground opacity-50',
    day_range_middle: 'aria-selected:bg-accent aria-selected:text-accent-foreground',
    day_hidden: 'invisible',
    ...classNames
  }} components={{
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    IconLeft: ({
      ...props
    }) => <ChevronLeftIcon className='h-4 w-4' />,
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    IconRight: ({
      ...props
    }) => <ChevronRightIcon className='h-4 w-4' />,
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    Dropdown: ({
      value,
      onChange,
      children,
      ...props
    }: DropdownProps) => {
      const options = React.Children.toArray(children) as React.ReactElement<React.HTMLProps<HTMLOptionElement>>[];
      const selected = options.find(child => child.props.value === value);
      const handleChange = (value: string) => {
        const changeEvent = {
          target: {
            value
          }
        } as React.ChangeEvent<HTMLSelectElement>;
        onChange?.(changeEvent);
      };
      return <Select value={value?.toString()} onValueChange={value => {
        handleChange(value);
      }}>
              <SelectTrigger className='h-[28px] pr-1.5 focus:ring-0'>
                <SelectValue>{selected?.props?.children}</SelectValue>
              </SelectTrigger>
              <SelectContent>
                <ScrollArea className='h-80'>
                  {options.map((option, id: number) => <SelectItem key={`${option.props.value}-${id}`} value={option.props.value?.toString() ?? ''}>
                      {option.props.children}
                    </SelectItem>)}
                </ScrollArea>
              </SelectContent>
            </Select>;
    }
  }} {...props} data-sentry-element="DayPicker" data-sentry-component="CalendarPicker" data-sentry-source-file="calendar.tsx" />;
}
CalendarPicker.displayName = 'Calendar';
export { CalendarPicker };