// import Image from "next/image";
import { Inter as FontSans } from 'next/font/google';
import Card_AircraftUpcomingBookings from '@/components/elements/cards/card_aircraftbookings';
import { cn } from '@/lib/utils';
import Card_AircraftDocs from '@/components/elements/cards/card_aircraftdocs';
import Card_BookingCalendar from '@/components/elements/cards/card_bookingCalendar';
import Card_AircraftStats from '@/components/elements/cards/card_aircraftstats';
const fontSans = FontSans({
  subsets: ['latin'],
  variable: '--font-inter'
});
export default function Home() {
  return <div data-sentry-component="Home" data-sentry-source-file="index.tsx">
      <div className={cn('antialiased', fontSans.variable)}>
        <div>
          <h1 className='scroll-m-20 p-5 text-4xl font-extrabold tracking-tight lg:text-5xl'>
            G-BAKW
          </h1>
        </div>
        <div className='flex flex-col gap-3 xl:flex-row'>
          <div className='xl:w-[65%]'>
            <Card_BookingCalendar data-sentry-element="Card_BookingCalendar" data-sentry-source-file="index.tsx" />
          </div>
          <div className='flex flex-1 flex-col space-y-3 xl:w-[35%]'>
            <div>
              <Card_AircraftStats data-sentry-element="Card_AircraftStats" data-sentry-source-file="index.tsx" />
            </div>
            <div className=''>
              <Card_AircraftDocs data-sentry-element="Card_AircraftDocs" data-sentry-source-file="index.tsx" />
            </div>
            <div className='flex flex-grow flex-col'>
              <Card_AircraftUpcomingBookings data-sentry-element="Card_AircraftUpcomingBookings" data-sentry-source-file="index.tsx" />
            </div>
          </div>
        </div>
      </div>
    </div>;
}