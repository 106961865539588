import { Skeleton } from '@/components/ui/skeleton';
import { Table, TableBody, TableRow, TableCell } from '@/components/ui/table';
import { trpc } from '@/lib/trpc/trpc';
import { IconAirTrafficControl } from '@tabler/icons-react';
import { addYears } from 'date-fns';
export default function Card_AircraftStats() {
  const getAircraftInfo = trpc.Aircraft.GetAircraftInfo.useQuery();
  const getAircraftMaintenanceTimeRemaining = trpc.Aircraft.GetAircraftMaintenanceTimeRemaining.useQuery();
  return <div className='flex flex-grow flex-col rounded-lg border p-3 text-card-foreground shadow' data-sentry-component="Card_AircraftStats" data-sentry-source-file="card_aircraftstats.tsx">
      <div className='flex items-center gap-2 p-2'>
        <IconAirTrafficControl data-sentry-element="IconAirTrafficControl" data-sentry-source-file="card_aircraftstats.tsx" />
        Aircraft Status
      </div>
      {getAircraftInfo.data && getAircraftMaintenanceTimeRemaining.data ? <Table>
          <TableBody>
            <TableRow>
              <TableCell>
                <b>Annual Check:</b>
              </TableCell>
              <TableCell>
                {addYears(getAircraftInfo.data.lastAnnual, 1).toLocaleDateString('en-GB')}
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell>
                <b>Insurance Expires:</b>
              </TableCell>
              <TableCell>
                {getAircraftInfo.data.insuranceExpiry.toLocaleDateString('en-GB')}
              </TableCell>
            </TableRow>
          </TableBody>
        </Table> : <div className='flex flex-col space-y-3'>
          <Skeleton className='h-[125px] w-[250px] rounded-xl' />
          <div className='space-y-2'>
            <Skeleton className='h-4 w-[250px]' />
            <Skeleton className='h-4 w-[200px]' />
          </div>
        </div>}
    </div>;
}