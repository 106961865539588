import FullCalendar from '@fullcalendar/react';
import dayGridPlugin from '@fullcalendar/daygrid';
import interactionPlugin from '@fullcalendar/interaction';
import timeGridPlugin from '@fullcalendar/timegrid';
import { DateSelectArg, EventClickArg } from '@fullcalendar/core/index.js';
import { inferRouterOutputs } from '@trpc/server';
import { AppRouter } from '@/server/routers/_app';
interface DayViewProps {
  calendarOnSelect: (selected: DateSelectArg) => void;
  events: inferRouterOutputs<AppRouter>['Booking']['GetCalendarEvents'];
  onClick: (event: EventClickArg) => void;
}
export default function DayView({
  ...props
}: DayViewProps) {
  return <div data-sentry-component="DayView" data-sentry-source-file="DayView.tsx">
      <FullCalendar plugins={[interactionPlugin, dayGridPlugin, timeGridPlugin]} initialView={document.body.clientWidth > 600 ? 'timeGridWeek' : 'timeGridThreeDay'} headerToolbar={{
      left: 'title',
      right: 'prev,next',
      center: ''
    }} views={{
      timeGridThreeDay: {
        type: 'timeGrid',
        duration: {
          days: 3
        },
        dayHeaderClassNames: 'text-sm'
      }
    }} locale='gb' selectable={true} select={props.calendarOnSelect} events={props.events} height={'auto'} duration={'01:00:00'} eventContent={({
      event
    }) => {
      return <div>
              <b>{event.title}</b>
              <br />
              <i>{event.extendedProps.comment}</i>
            </div>;
    }} titleFormat={{
      day: '2-digit'
    }} allDaySlot={false} nowIndicator slotMinTime={'07:00:00'} slotMaxTime={'20:00:00'} longPressDelay={500} editable={false} eventClick={info => {
      props.onClick(info);
    }} data-sentry-element="FullCalendar" data-sentry-source-file="DayView.tsx" />
    </div>;
}